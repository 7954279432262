body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* box sizing fix - https://css-tricks.com/box-sizing */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

